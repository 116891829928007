// const BASEURL = 'https://admin-api.lalpathlabs.com/';
const BASEURL = `https://uatapi.lalpathlabs.com/`;
const API_URL = {
  BASEURL: BASEURL,
  API_BASEURL: `${BASEURL}api`,
  IMAGE_URL: BASEURL,
  S3_BANNER_BASE: `${BASEURL}api/s3/download-image?imageKey=`,
  S3_BLOG_BASE: `${BASEURL}api/s3/blog/download-image?imageKey=`,
  LOGIN: `/auth/signin`,
  DOCUMENTPATH: `https://liveapi.lalpathlabs.com/`,
  DASHBOARD_FAQ_COUNT: `/dashboard/faqs/count`,
  DASHBOARD_USER_COUNT: `/dashboard/users/count`,
  DASHBOARD_JOB_ADD_COUNT: `/dashboard/jobs/count`,
  USER_LIST: `/users/list`,
  USER_ADD: `/users/add`,
  USER_UPDATE: `/users/update`,
  USER_STATUS_UPDATE: `/users/update/status`,
  UPDATE_PASSWORD: `/users/changepassword`,
  USER_BY_ID: `/users/details`,
  ROLE_LIST: `/role/list`,
  ROLE_ADD: `/role/add`,
  ROLE_UPDATE: `/role/update`,
  ROLE_BY_ID: `/role/details`,
  FILTER_DATA: '/master/filterdata',
  CITY_LIST: '/city/list',
  PERMISSION_UPDATE: '/role/updatepermissions',
  FAQ_LIST: `faqs/list`,
  FAQ_ADD: `/faqs/add`,
  FAQ_UPDATE: `/faqs/update`,
  FAQ_BY_ID: `/faqs/details`,
  FAQ_CATEGORY_LIST: `faqs/category/list`,
  FAQ_CATEGORY_ADD: `/faqs/category/add`,
  FAQ_CATEGORY_UPDATE: `/faqs/category/update`,
  FAQ_CATEGORY_BY_ID: `/faqs/category/details`,
  JOBAPPLICATIONS_LIST: `jobapplications/list`,
  JOBAPPLICATIONS_DETAILS: `jobapplications/details`,
  JOB_APPLICATION_UPDATE: `jobapplications/update`,
  DEPARTMENT_LIST: `/department/list`,
  DEPARTMENT_DETAILS: `/department/details`,
  DEPARTMENT_ADD: `/department/add`,
  DEPARTMENT_UPDATE: `/department/update`,
  TEST_DEPARTMENT_LIST: `/test/list`,
  TEST_DEPARTMENT_ADD: `/test/add`,
  TEST_DEPARTMENT_DETAILS: `/test/details`,
  TEST_DEPARTMENT_UPDATE: `/test/update`,
  STATE_CITY: `state/list`,
  JOBADD_LIST: `/jobs/list`,
  JOBADD_DETAILS: `jobs/details`,
  JOBADD_ADD: `jobs/add`,
  JOBADD_UPDATE: `jobs/update`,
  JOB_ROLE: `jobs-role/list`,
  SEO_LIST: `seo/list`,
  SEO_DETAILS: `seo/details`,
  SEO_UPDATE: `seo/update`,
  SEO_ADD: `seo/add`,
  INVESTOR_CATEGORY_LIST: `investor/category/list`,
  INVESTOR_CATEGORY_DETAILS: `investor/category/details`,
  INVESTOR_CATEGORY_UPDATE: `investor/category/update`,
  INVESTOR_CATEGORY_ADD: `investor/category/add`,
  UPLOAD_ATTACHMENT: `upload/add?module_name=attachments`,
  TICKET_ADD: `ticket/add`,
  TICKET_LIST: `ticket/list`,
  TICKET_DETAILS: `ticket/details`,
  TICKET_UPDATE: `ticket/update`,
  INVESTOR_ITEM_ADD: `investor/item/add`,
  INVESTOR_ITEM_LIST: `investor/item/1`,
  INVESTOR_ITEM_DETAILS: `investor/item/1`,
  INVESTOR_ITEM_LIST2: `investor/item/2`,
  INVESTOR_ITEM_LIST3: `investor/item/3`,
  INVESTOR_ITEM_DETAILS2: `investor/item/2`,
  INVESTOR_ITEM_DETAILS3: `investor/item/3`,
  INVESTOR_ITEM_UPDATE: `investor/item/update`,
  INVESTOR_ITEM_UPDATE2: `investor/item/update`,
  INVESTOR_ITEM_UPDATE3: `investor/item/update`,
  FETCH_INVESTOR_INFO: `/investor/information`,
  INVESTOR_UPDATE_JSON: `/investor/updateJsonData`,
  BANNER_LIST: `/banner/list`,
  BANNER_DETAILS: `/banner/details`,
  BANNER_ADD: `/banner/add`,
  BANNER_UPDATE: `/banner/update`,
  BANNER_IMG_ADD: "/s3/image",
  COUPON_CATEGORY: "/coupon/category/list",
  COUPON_CATEGORY_ADD: "/coupon/category/add",
  COUPON_CATEGORY_DETAIL: "/coupon/category/details",
  COUPON_CATEGORY_UPDATE: "/coupon/category/update",
  COUPON_LIST: "/coupon/list",
  COUPON_ADD: "/coupon/add",
  COUPON_DETAIL: "/coupon/details",
  COUPON_UPDATE: "/coupon/update",
  BTL_OFFERS: "/coupon/allBtlOffers",
  BTL_CUSTOMERS: "/btl/users",
  BTL_TESTCODE: "/btl/offersTestcodes",
  WHATS_BANNER_LIST: `/whatsnew/list`,
  WHATS_BANNER_DETAILS: `/whatsnew/details`,
  WHATS_BANNER_ADD: `/whatsnew/add`,
  WHATS_BANNER_UPDATE: `/whatsnew/update`,
  BTL_OFFERS_STATUS_UPDATE: `/coupon/update/status`,
  BTL_CUSTOMERS_LIST: `btl/users/export`,
  BECOME_PARTNER_LIST: `businessPartner/list`,
  TEAM_MEMBERS: "/teammember/list",
  TEAM_ADD: "/teammember/add",
  TEAM_DETAIL: "/teammember/details",
  TEAM_UPDATE: "/teammember/update",

  BLOG_IMG_ADD: "/s3/upload/blogattachment",
  BLOG_ATTACHMENTS_LIST: "/blogattachment/list",
  BLOG_ATTACHMENTS__ADD: "/blogattachment/add",
  BLOG_ATTACHMENTS__DELETE: "/whatsnew/delete",
  MANAGE_DOWNLOAD_REPORT_LIST: "/oursubsidiaries/list",
  MANAGE_DOWNLOAD_REPORT_ADD: "/oursubsidiaries/add",
  MANAGE_DOWNLOAD_REPORT_UPDATE: "/oursubsidiaries/update",
  MANAGE_DOWNLOAD_REPORT_DETAIL: "/oursubsidiaries/details",
  FELOWSHIP_LIST: `/programs/list`,
  FELOWSHIP_DETAILS: `/programs/details`,
  HOME_COLLECTION_LIST: `/homecollection/list`,
  CORPORATE_TIEUP_LIST: `/businessPartner/admin/corporate-tieup/list`,
  CORPORATE_TIEUP_ADD: '/businessPartner/admin/corporate-tieup/add',
  CORPORATE_ASSING: 'businessPartner/admin/corporate-tieup/assignUser',
  SMS_PARTNERLIST: `/sms-partners/list`,
  SMS_PARTNERLIST_UPDATE: `/sms-partners/update/status`,
  ZONE_LIST: `/zones/admin/list`,
  ZONE_UPDATE: "/zones/admin/update",
  ZONE_ADD: '/zones/admin/add',
  ZONE_DETAILS_BY_ID: '/zones/admin/details',
  LEAD_PAGE_LIST: "/leadpages/admin/list",
  LEAD_PAGE_ADD: "/leadpages/admin/add",
  LEAD_PAGE_DETAIL: "/leadpages/admin/details",
  LEAD_PAGE_UPDATE: "/leadpages/admin/update",
  
};
export default API_URL;
