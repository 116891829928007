import { Button, Col, Modal, Table, Tooltip, message, Row, notification } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FileExcelFilled } from '@ant-design/icons';
import HeadingBack from '../../component/HeadingBack';
import { CSVLink } from "react-csv"
import { JobApplicationsServices } from '../../redux/Services';
import CorporateTieUpFilter from './corporateTieUpFilter';
import API_URL from "../../api/url";
import { usePermission } from '../../hooks/usePermission';
import moment from 'moment';
import { getBusinessProfession } from "../../utils";
import SvgIcon from '../../const/SvgIcon';
import { DownOutlined, UserOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const CorporateTieUp = () => {

    const permission = usePermission("Corporate Tie Up", "List");
    const url = API_URL.S3_BANNER_BASE
    const navigate = useNavigate();
    const [filter, setFilter] = useState({});
    const [limit, setLimit] = useState(100);
    const [offset, setOffset] = useState(0);
    const [isFilter, setisFilter] = useState(0);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const CorporateTieUpList = useSelector(state => state.jobAppliction.CorporateTieUpList || []);
    const totalCorporateTieUp = useSelector(state => state.jobAppliction.totalCorporateTieUpList || 0);
    const { mutate: getCorporateTieUpList, isLoading } = useMutation((data) => dispatch(JobApplicationsServices.fetchCorporateTieUpApi(limit, offset, data)));
    const { mutate: assignCorpUser } = useMutation((data) => dispatch(JobApplicationsServices.assignCorpUser(data)), {
        onSuccess: () => {
            getCorporateTieUpList(filter);
            setSelectedRows([]);
            setTableKey(prevKey => prevKey + 1);
            setIsModalOpen(false);
        }
    });
    const [selectionType, setSelectionType] = useState('checkbox');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [position, setPosition] = useState('end');
    const [selectedAssignee, setSelectedAssignee] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [tableKey, setTableKey] = useState(0);
    const [modal2Open, setModal2Open] = useState(false);
const [raw, setRaw] = useState({});
    const showModal = () => {
        if (selectedRows.length === 0) {
            notification["error"]({
                message: "Please select at least one row"
            });
            return;
        }

        if (selectedRows.length > 20) {
            notification["warning"]({
                message: "You can select up to 20 rows only"
            });
            setIsModalOpen(false)
            return;
        }

        setIsModalOpen(true);
    };
    const handleOk = () => {
        const alreadyAssigned = selectedRows.some(row => row.assigned_to);

        if (alreadyAssigned) {
            Modal.confirm({
                title: 'Some leads are already assigned',
                icon: <ExclamationCircleOutlined />,
                content: 'Some of the selected leads are already assigned. Do you want to reassign them?',
                onOk() {
                    assignLeads();
                },
            });
        } else {
            assignLeads();
        }
    };

    const assignLeads = () => {
        const payload = {
            leads: selectedRows.map((row) => ({
                lead_id: row.id,
                assignUser: selectedAssignee,
            })),
        };
        assignCorpUser(payload);
    };
    const handleCancel = () => {
        setSelectedAssignee(null);
        setIsModalOpen(false);
    };
    const handleButtonClick = (email) => {
        setSelectedAssignee(email);
    };

    useEffect(() => {
        getCorporateTieUpList(filter);
        return () => { }
    }, [filter, offset, limit])

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            if (selectedRows.length <= 20) {
                setSelectedRows(selectedRows);
            } else {
                notification["warning"]({
                    message: "You can select up to 20 rows only"
                });
                setSelectedRows(selectedRows);
            }
        },
        getCheckboxProps: (record) => ({
            id: record.id,
            // disabled: selectedRows.length >= 10 && !selectedRows.some(row => row.id === record.id),
        }),


    };

    const assigneeData = [
        {
            email: 'karansharma02015@gmail.com',
            name: 'Karan'
        },
        {
            email: 'abhas.gupta@abym.in',
            name: 'Abhas'
        },
        {
            email: 'debasish.chatterjee@lalpathlabs.com',
            name: 'Debasish'
        }
    ];

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className: 'table_first_col',
            render: (value, item, index) => (page - 1) * 100 + index + 1
        },
        {
            title: 'Assigned To',
            dataIndex: 'assigned_to',
            key: 'assigned_to',
            width: '200px',

        },
        {
            title: 'First Name',
            dataIndex: 'first_name',
            key: 'first_name',
            className: 'text-capitalize',
        },
        {
            title: 'Last Name',
            dataIndex: 'last_name',
            key: 'last_name',
            className: 'text-capitalize',
        },
        {
            title: 'Email',
            dataIndex: 'email_id',
            key: 'email_id',
            width: 100,
        },
        {
            title: 'Mobile',
            dataIndex: 'contact_number',
            key: 'contact_number',
        },
        {
            title: 'Corporate Name',
            dataIndex: 'corporate_name',
            key: 'corporate_name',
        },
        {
            title: 'Company Profile/Nature of Business',
            dataIndex: 'company_profile',
            key: 'company_profile',
        },
        {
            title: 'Industry',
            dataIndex: 'industry',
            key: 'industry',
        },
        {
            title: 'Approx Number of Employees PAN INDIA',
            dataIndex: 'employees_count',
            key: 'employees_count',
        },
        {
            title: 'Corporate Address',
            dataIndex: 'corporate_address',
            key: 'corporate_address',
            width: 100
        },
        {
            title: 'State',
            dataIndex: 'state_name',
            key: 'state_name',
        },
        {
            title: 'City',
            dataIndex: 'city_name',
            key: 'city_name',
        },
        {
            title: 'Investigations',
            dataIndex: 'investigations_file',
            key: 'investigations_file',
            render: (value, item, index) => <span>{item?.investigations_file && <Button className="btl_img_class" type="primary" onClick={() => { setRaw(item?.investigations_file ? `${url}${item.investigations_file}` : "{}"); setModal2Open(true) }}><img className="btl_img" src={`${url}${item?.investigations_file}`} crossorigin="anonymous" /></Button>}</span>
            // render: (value, item, index) => {
            //     return (
            //         item.investigations_file ? <a style={{ textDecoration: "underline" }} href={item.investigations_file} target="_blank">View</a> : 'NA'
            //     )
            // }
        },
        {
            title: 'Corporate Requirement For Tie Up',
            dataIndex: 'corporate_requirement',
            key: 'corporate_requirement',
        },

        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            className: 'text-capitalize',
            render: (value, item, index) => {
                return (
                    <div
                        dangerouslySetInnerHTML={{ __html: item.remarks ? item.remarks : "NA" }}
                    />
                )
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (value, item, index) => {
                return (
                    <>{moment.utc(item.createdAt).format('DD-MM-YYYY H:mm:ss')}</>
                )
            }
        },

    ];
    return (
        <div>
            <div className='position-relative part-filter flex justify-content-between align-items-center mb-20'>
                <HeadingBack title={"Total Leads"} subHeading={`Total ${totalCorporateTieUp || 0} Leads`} />
                <div>
                    <Col className='  '>
                        <div className=' flex justify-content-between gap50'>
                            <button type="primary" onClick={(value) => showModal()} iconPosition={position} className='jop_btn'> Select Assignee <SvgIcon.Add width={40} height={40} className="cursor-pointer" /></button>
                            <Tooltip title="Add"><span className='jop_btn' onClick={() => navigate("/corporate-tie-up/add")}>Add<SvgIcon.Add width={40} height={40} className="cursor-pointer" /></span></Tooltip>
                        </div>
                    </Col>
                </div>


            </div>
            <div className='  '>
                <CorporateTieUpFilter filter={filter} setFilter={setFilter} setPage={setPage} setOffset={setOffset} limit={setLimit} isFilter={setisFilter} />


            </div>
            <div className='tableResponsive mt-10'>
                <Table
                    key={tableKey}
                    rowSelection={{
                        type: selectionType,
                        ...rowSelection,
                    }}
                    rowKey='id'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(CorporateTieUpList) && CorporateTieUpList?.length > 0 ? CorporateTieUpList : []}
                    pagination={{
                        current: page,
                        pageSize: limit,
                        total: totalCorporateTieUp,
                        onChange(current, pageSize) {
                            setPage(current);
                            setOffset(current == 1 ? 0 : (((current - 1) * limit)));
                        },
                        hideOnSinglePage: true
                    }}

                />
            </div>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{ disabled: !selectedAssignee }}
            >
                <Row gutter={[16, 16]} justify="center">
                    {assigneeData.map((item) => (
                        <Col span={24} className="button-col" key={item.email}>
                            <Button
                                className={selectedAssignee === item.email ? 'active' : ''}
                                style={{ width: '90%' }}
                                onClick={() => handleButtonClick(item.email)}
                            >
                                {item.name}
                            </Button>
                        </Col>
                    ))}
                </Row>
            </Modal>
            <Modal
                className='btl_modal'
                centered
                open={modal2Open}
                onOk={() => setModal2Open(false)}
                onCancel={() => setModal2Open(false)}
                footer={null}
            >
                <img src={(raw)} crossOrigin='anymouse' />
            </Modal>

        </div>
    )
}

export default CorporateTieUp


