import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { Collapse, List, Col, Row, Table, Tooltip, Button, Modal, Typography } from "antd";
import { SmsServices } from "../../redux/Services";
import HeadingBack from "../../component/HeadingBack";
import { useDispatch, useSelector } from "react-redux";
import SvgIcon from "../../const/SvgIcon";
import { usePermission } from '../../hooks/usePermission';
import { useNavigate, useParams } from 'react-router-dom';
import { notification } from "antd";

const { Title } = Typography;
const { Panel } = Collapse;
const SmsPartners = () => {
  const permission = usePermission("SMS Partners", "List");
  const { Id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const smspartnerList = useSelector((state) => state.sms.SmsPartnerList || "");
  const { mutate: getSmsPartnerList, isLoading } = useMutation((data) => dispatch(SmsServices.fetchSmsPartnerApi(data)));
  const { mutate: updateSmsPartnerStatus } = useMutation((data) => dispatch(SmsServices.updateSmsPartnerApi(data, Id, navigate)));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [smsProvider, setSmsProvider] = useState('');
  const [status, setStatus] = useState('');
  const showModal = (value, newStatus) => {
    setSmsProvider(value.value)
    setStatus(newStatus)
    setIsModalOpen(true);
  };
  const handleOk = () => {
    updateSmsPartnerStatus({ SmsProviderCode: smsProvider, Status: status });
    setTimeout(() => {
      getSmsPartnerList()
    }, 1000);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    getSmsPartnerList();
  }, []);
  const activeUser = smspartnerList.find(item => item.Status);
  return (
    <div>
      <Row
        gutter={[20, 20]}
        align={"middle"}
        className="justify-content-between"
      >
        <Col lg={20} md={3} sm={24} xs={24}>
          <HeadingBack title={"SMS Partners"} subHeading={` `} />
        </Col>
        <Col lg={4} md={24} sm={24} xs={24} style={{ paddingTop: '20px' }}>
          <div className="flex justify-content-left">
            <Tooltip title="Add">
              <span className="jop_btn">
                Add
                <SvgIcon.Add
                  width={40}
                  height={40}
                  className="cursor-pointer"
                />
              </span>
            </Tooltip>
          </div>
        </Col>
      </Row>
     
      {activeUser && (
        <Title level={4} style={{ margin: '20px 0px' }}>Currently Active : {activeUser.SmsProviderCode}</Title>
      )}
      {smspartnerList.length === 0 ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Title level={4}>No Data Found !!</Title>
        </div>
      ) : (
      <Collapse accordion defaultActiveKey={['1']} expandIconPosition="end">
        {smspartnerList.map((item, index) => (
          <Panel header={`${item.SmsProviderCode} - ${item?.Status ? "Active" : "Inactive"}`} key={item.key}>
            <Row
              gutter={[20, 20]}
              align={"middle"}
              className="justify-content-between"
            >
              <Col lg={12} md={3} sm={24} xs={24}>
                <strong>Company Name</strong>
              </Col>
              <Col lg={12} md={3} sm={24} xs={24}>
                <div className="due-date muted">{item.SmsProviderCode}</div>
              </Col>
              <Col lg={12} md={3} sm={24} xs={24}>
                <strong>BaseUrl</strong>
              </Col>
              <Col lg={12} md={3} sm={24} xs={24}>
                <div className="due-date muted">{item.BaseUrl}</div>
              </Col>
              <Col lg={12} md={3} sm={24} xs={24}>
                <strong>UserName</strong>
              </Col>
              <Col lg={12} md={3} sm={24} xs={24}>
                <div className="due-date muted">{item.UserName}</div>
              </Col>
              {/* <Col lg={12} md={3} sm={24} xs={24}>
        <strong>Password</strong>
        </Col>
        <Col lg={12} md={3} sm={24} xs={24}>
        <div className="due-date muted">{item?.password}</div>
        </Col> */}
        <Col lg={12} md={3} sm={24} xs={24}>
        <strong>Status</strong>
        </Col>  
        <Col lg={12} md={3} sm={24} xs={24}>
          <div className="active_inactive">
        <button onClick={(value) => showModal({value:item.SmsProviderCode},!item.Status ? 1 : 0)} className={item?.Status ? "status_active" : "status_inactive"}>{item?.Status ? "Active" : "Inactive"}</button>
        {/* <button href="" onClick={(value) => handleStatusChange({value:item.SmsProviderCode},!item.Status ? 1 : 0)} className={item?.Status ? "status_active" : "status_inactive"}>{item?.Status ? "Active" : "Inactive"}</button> */}
        </div>
        </Col>
      </Row>
        </Panel>
      ))}
    </Collapse>
  )}
    <Modal open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <p style={{ marginTop: '2em' }}>Arey you sure to change the current status of the SMS Partner? If yes, all the other existing status will be reset.</p>
      </Modal>
    </div>
  );
};

export default SmsPartners;
