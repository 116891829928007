export const statecities = [
    {
        "State": "Tripura",
        "City": ["Agartala"]
    },
    {
        "State": "Uttar Pradesh",
        "City": ["Agra","Allahabad","Aligarh","Amroha","Azamgarh","Aminagar Sarai","Bareilly","Bulandshahar","Bijnor","Chhitauni","Dadri","Deoria","Faizabad","Firozabad","Ghaziabad","Gorakhpur","Greater Noida","Hapur","Jaunpur","Jhansi","Kanpur","Khurja","Loni","Lucknow","Mathura","Meerut","Modinagar","Moradabad","Muzaffarnagar","Noida","Puranpur","Rampur","Saharanpur","Sultanpur","Varanasi"]
    },
    {
        "State": "Gujarat",
        "City": ["Ahmedabad","Vadodara"]
    },
    {
        "State": "Rajasthan",
        "City": ["Alwar","Bikaner","Jaipur","Jodhpur","Udaipur","Sri Ganganagar","Kota"]
    },
    {
        "State": "Haryana",
        "City": ["Ambala","Ballabhgarh","Bahadurgarh","Bhiwani","Faridabad","Gurgaon","Hisar","Kaithal","Karnal","Kurukshetra","Palwal","Panchkula","Panipat","Rewari","Rohtak","Sirsa","Sohna","Sonipat","Yamuna Nagar"]
    },
    {
        "State": "Maharashtra",
        "City": ["Amravati","Aurangabad","Mumbai","Nagpur","Nashik","Pune","Thane"]
    },
    {
        "State": "Punjab",
        "City": ["Amritsar","Bhatinda","Ferozpur","Hoshiarpur","Jalandhar","Khanna","Ludhiana","Majitha","Moga","Mohali","Pathankot","Patiala"]
    },
    {
        "State": "Jammu and Kashmir",
        "City": ["Anantnag","Baramula","Jammu","Srinagar"]
    },
    {
        "State": "Bihar",
        "City": ["Begusarai","Bhagalpur","Darbhanga","Gaya","Hajipur","Muzaffarpur","Patna","Purnia"]
    },
    {
        "State": "Karnataka",
        "City": ["Bengaluru","Hubli","Mangalore","Mysore"]
    },
    {
        "State": "Chhattisgarh",
        "City": ["Bhilai","Bilaspur","Raipur"]
    },
    {
        "State": "Madhya Pradesh",
        "City": ["Bhopal","Gwalior","Indore","Jabalpur","Sagar"]
    },
    {
        "State": "Odisha",
        "City": ["Bhubaneswar","Cuttack"]
    },
    {
        "State": "Chandigarh",
        "City": ["Chandigarh"]
    },
    {
        "State": "Tamil Nadu",
        "City": ["Chennai","Coimbatore"]
    },
    {
        "State": "Jharkhand",
        "City": ["Daltonganj","Dhanbad","Jamshedpur","Ranchi"]
    },
    {
        "State": "Uttarakhand",
        "City": ["Dehradun","Haldwani","Haridwar","Rishikesh","Roorkee"]
    },
    {
        "State": "Delhi",
        "City": ["Delhi"]
    },
    {
        "State": "Assam",
        "City": ["Dibrugarh","Guwahati","Silchar"]
    },
    {
        "State": "West Bengal",
        "City": ["Durgapur","Howrah","Kolkata","Krishnanagar","New Town","Siliguri"]
    },
    {
        "State": "Goa",
        "City": ["Goa Velha"]
    },
    {
        "State": "Himachal Pradesh",
        "City": ["Hamirpur","Kangra","Shimla"]
    },
    {
        "State": "Telangana",
        "City": ["Hyderabad"]
    },
    {
        "State": "Kerala",
        "City": ["Kochi"]
    },
    {
        "State": "Meghalaya",
        "City": ["Shillong"]
    },
    {
        "State": "Andhra Pradesh",
        "City": ["Vijayawada","Visakhapatnam"]
    }
]