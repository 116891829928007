let year = new Date()?.getFullYear();
export const SHIFT_TIME_CONST = 19;
export const NO_PERMISSION_MESSAGE = "You don't have permission to access this.Please contact your administrator.";
export const MONTH_DATA = [
    { label: "Jan", value: 1, year: year },
    { label: "Feb", value: 2, year: year },
    { label: "Mar", value: 3, year: year },
    { label: "Apr", value: 4, year: year },
    { label: "May", value: 5, year: year },
    { label: "Jun", value: 6, year: year },
    { label: "Jul", value: 7, year: year },
    { label: "Aug", value: 8, year: year },
    { label: "Sep", value: 9, year: year },
    { label: "Oct", value: 10, year: year },
    { label: "Nov", value: 11, year: year },
    { label: "Dec", value: 12, year: year }
]
export const OPEN_KEY_DATA2 = [
    { openKey: "faq-management", path: ["faqs", "faq-category"] },
    { openKey: "user-management", path: ["users", "roles",] }
]
export const OPEN_KEY_DATA = [
    { openKey: [], selected: ["dashboard"], path: "" },
    { openKey: [], selected: ["dashboard"], path: "dashboard" },
    { openKey: [], selected: ["settings"], path: "settings" },
    { openKey: [], selected: ["testmanagement"], path: "test-management" },
    { openKey: [], selected: ["ticket-management"], path: "ticket-management" },
    { openKey: ['user-management'], selected: ["users"], path: "users" },
    { openKey: ['user-management'], selected: ["users"], path: "user-list" },
    { openKey: ['user-management'], selected: ["users"], path: "user-add" },
    { openKey: ['user-management'], selected: ["users"], path: "user-edit" },
    { openKey: ['user-management'], selected: ["roles"], path: "roles" },
    { openKey: ['user-management'], selected: ["roles"], path: "role-list" },
    { openKey: ['user-management'], selected: ["roles"], path: "role-add" },
    { openKey: ['user-management'], selected: ["roles"], path: "role-edit" },
    { openKey: ['user-management'], selected: ["roles"], path: "role-user-permission" },
    { openKey: ['faq-management'], selected: ["faqs"], path: "faqs" },
    { openKey: ['faq-management'], selected: ["faqs"], path: "faq-list" },
    { openKey: ['faq-management'], selected: ["faqs"], path: "faq-add" },
    { openKey: ['faq-management'], selected: ["faqs"], path: "faq-edit" },
    { openKey: ['faq-management'], selected: ["faq-category"], path: "faq-category" },
    { openKey: ['faq-management'], selected: ["faq-category"], path: "faq-category-list" },
    { openKey: ['faq-management'], selected: ["faq-category"], path: "faq-category-add" },
    { openKey: ['faq-management'], selected: ["faq-category"], path: "faq-category-edit" },
    { openKey: ['testmanagement'], selected: ["testmanagement"], path: "test-management-edit" },
    { openKey: ['testmanagement'], selected: ["testmanagement"], path: "test-management-add" },
    { openKey: ['department'], selected: ["department"], path: "department-edit" },
    { openKey: ['department'], selected: ["department"], path: "department-add" },
    { openKey: [], selected: ["department"], path: "department" },
    { openKey: ['job-applications'], selected: ["job-applications"], path: "job-applications-edit" },
    { openKey: ['job-applications'], selected: ["job-applications"], path: "job-applications-add" },
    { openKey: [], selected: ["job-applications"], path: "job-applications" },
    { openKey: ['job-post'], selected: ["job-post"], path: "jobs-edit" },
    { openKey: ['job-post'], selected: ["job-post"], path: "jobs-add" },
    { openKey: [], selected: ["jobs"], path: "job-post" }, 
    { openKey: ['seo'], selected: ["seo"], path: "seo-management-add" },
    { openKey: ['seo'], selected: ["seo"], path: "seo-management-edit" },
    { openKey: [], selected: ["seo"], path: "seo-management" },
    { openKey: [], selected: ["investor Category"], path: "investor-category" },
    { openKey: ['investor Category'], selected: ["investor Category"], path: "investor-category-add" },
    { openKey: ['investor Category'], selected: ["investor Category"], path: "investor-category-edit" }, 
    { openKey: [], selected: ["investor Category"], path: "investor-subcategory" },
    { openKey: ['investor Category'], selected: ["investor Category"], path: "investor-subcategory-add" },
    { openKey: ['investor Category'], selected: ["investor Category"], path: "investor-subcategory-edit" },
    { openKey: [], selected: ["financials"], path: "investor-financials" },
    { openKey: ['investor Category'], selected: ["financials"], path: "investor-financials-add" },
    { openKey: ['investor Category'], selected: ["financials"], path: "investor-financials-edit" }, 
    { openKey: [], selected: ["investor"], path: "investor-information" },
    { openKey: ['investor'], selected: ["investor"], path: "investor-information-add" },
    { openKey: ['investor'], selected: ["investor"], path: "investor-information-edit" }, 
    { openKey: [], selected: ["corporate-governance"], path: "investor-corporate-governance" },
    { openKey: ['corporate-governance'], selected: ["corporate-governance"], path: "investor-corporate-governance-add" },
    { openKey: ['corporate-governance'], selected: ["corporate-governance"], path: "/investor-corporate-governance-edit" },
    { openKey: [], selected: ["ticket-management"], path: "ticket-management" },
    { openKey: ['ticket-management'], selected: ["ticket-management"], path: "ticket-management-add" },
    { openKey: ['ticket-management'], selected: ["ticket-management"], path: "ticket-management-edit" },
    { openKey: ['btl-management'], selected: ["btl"], path: "btl" },
    { openKey: ['btl-management'], selected: ["btl"], path: "btl-add" },
    { openKey: ['btl-management'], selected: ["btl"], path: "btl-edit" },
    { openKey: ['btl-management'], selected: ["btl-customers"], path: "btl-customers" },
    { openKey: ["Banner Management"], selected: ["banner-management"], path: "page-banner" },
    { openKey: ["Banner Management"], selected: ["banner-management"], path: "page-banner-add" },
    { openKey: ["Banner Management"], selected: ["banner-management"], path: "page-banne-edit" },  
    { openKey: ["What's New"], selected: ["whats-new"], path: "whats-new" },
    { openKey: ["What's New"], selected: ["whats-new"], path: "whats-new-add" },
    { openKey: ["What's New"], selected: ["whats-new"], path: "whats-new-edit" },
    { openKey: ["team-management"], selected: ["team-management"], path: "our-team" },
    { openKey: ["team-management"], selected: ["team-management"], path: "our-team-add" },
    { openKey: ["team-management"], selected: ["team-management"], path: "our-team-edit" },
    { openKey: ["blog-attachment"], selected: ["blog-attachment"], path: "blog-management" },
    { openKey: ["blog-attachment"], selected: ["blog-attachment"], path: "blog-management-add" },
    { openKey: [], selected: ["fellowship-program"], path: "fellowship-program" },
    { openKey: [], selected: ["fellowship-program"], path: "fellowship-program-view" },
    { openKey: ['business-partnership'], selected: ["business-partner"], path: "business-partner" },
    { openKey: ['business-partnership'], selected: ["corporate-tie-up"], path: "corporate-tie-up" },
    { openKey: ['business-partnership'], selected: ["corporate-tie-up"], path: "corporate-tie-up-add" },
    { openKey: [], selected: ["manage-download-report"], path: "manage-download-report" },
    { openKey: ["sms-partners"], selected: ["sms-partners"], path: "sms-partners" },
    { openKey: ['promotions-management'], selected: ["Promotions&DiscountCategory"], path: "coupon-category" },
    { openKey: ['promotions-management'], selected: ["Promotions-Item"], path: "coupons" },
    { openKey: ['lead-management'], selected: ["lead-zone-management"], path: "manage-zone" },
    { openKey: ['lead-management'], selected: ["lead-zone-management"], path: "manage-zone-add" },
    { openKey: ['lead-management'], selected: ["lead-zone-management"], path: "manage-zone-edit" },
    { openKey: ['lead-management'], selected: ["lead-page-management"], path: "manage-page" },
    { openKey: ['lead-management'], selected: ["lead-page-management"], path: "manage-page-add" },
    { openKey: ['lead-management'], selected: ["lead-page-management"], path: "manage-page-edit" },
]