import { Table, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HeadingBack from '../../component/HeadingBack';
import SvgIcon from '../../const/SvgIcon'
import { usePermission } from '../../hooks/usePermission';
import { BannerServices, UserServices } from '../../redux/Services';
import { FileImageTwoTone } from '@ant-design/icons';

const Banner = () => {
    const permission = usePermission("Banner Management", "List");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const bannerList = useSelector(state => state.banner.bannerList || []);
    const totalBanner = useSelector(state => state.banner.TotalRecord || 0);
    const BannerPageSorce = useSelector(state => state.user.filterData?.PageSource || {});
    const { mutate: fetchBannerList, isLoading } = useMutation((data) => dispatch(BannerServices.fetchBannerListApi(data)));
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    useEffect(() => {
        fetchBannerList({});
        return () => { }
    }, [])

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'sno',
            key: 'sno',
            className: 'table_first_col',
            render: (value, item, index) => (page - 1) * 10 + index + 1
        },
        {
            title: 'Page Source',
            dataIndex: 'PageSource',
            key: 'PageSource',
            className: 'text-capitalize',
            render: (_, item) => BannerPageSorce[item?.PageSource] || ""
        },
        {
            title: 'Banner Label',
            dataIndex: 'BannerLabel',
            key: 'BannerLabel',
            className: 'text-capitalize'
        },
        {
            title: 'Cities',
            dataIndex: 'Cities',
            key: 'Cities',
            className: 'text-capitalize'
        },
        {
            title: 'Button Text',
            dataIndex: 'BannerText',
            key: 'BannerText',
            className: 'text-capitalize'
        },
        {
            title: 'Web Banner',
            dataIndex: 'WebBanner',
            key: 'WebBanner',
            className: 'text-capitalize',
            render: (value, item, index) => <span>{item?.WebBanner && <Typography.Link href={item?.WebBanner} target='_blank'><FileImageTwoTone style={{ fontSize: "30px" }} /></Typography.Link>}</span>
        },
        {
            title: 'Mobile Banner',
            dataIndex: 'MobileBanner',
            key: 'MobileBanner',
            className: 'text-capitalize',
            render: (value, item, index) => <span>{item?.MobileBanner && <Typography.Link href={item?.MobileBanner} target='_blank'><FileImageTwoTone style={{ fontSize: "30px" }} /></Typography.Link>}</span>
        },
        {
            title: 'Alt Tag',
            dataIndex: 'BannerAltTag',
            key: 'AltTag',
        },
        {
            title: 'Web Landing URL',
            dataIndex: 'WebLandingUrl',
            key: 'WebLandingUrl',
        },
        {
            title: 'Mobile Landing URL',
            dataIndex: 'MobileLandingUrl',
            key: 'MobileLandingUrl',
        },
        {
            title: 'Order By',
            dataIndex: 'OrderBy',
            key: 'OrderBy'
        },
        {
            title: 'Status',
            dataIndex: 'Status',
            key: 'Status',
            className: 'table_status_col',
            render: (value, item, index) => {
                return (
                    <span className={item?.Status ? "status_active" : "status_inactive"}>{item?.Status ? "Active" : "Inactive"}</span>
                )
            }
        },
        {
            title: 'Actions',
            dataIndex: 'action',
            key: 'action',
            className: 'table_action_col',
            render: (value, item, index) => {
                return (
                    <Tooltip title="Edit"><span className='cursor-pointer text-left' onClick={() => navigate(`/page-banner/edit/${item?.Id}`)}><SvgIcon.Edit className="scale-icon edit-icon" /></span></Tooltip>
                )
            }
        }
    ];
    return (
        <div>
            <div className='flex'>
                <div className='w-100'>
                    <HeadingBack title={"Banner"} subHeading={`Total ${totalBanner || 0} Banner`} />
                </div>
                <div className=''>
                    <Tooltip title="Add"><span className='jop_btn' onClick={() => navigate("/page-banner/add")}>Add<SvgIcon.Add width={40} height={40} className="cursor-pointer" /></span></Tooltip>
                </div>
            </div>
            <div className='tableResponsive mt-10'>
                <Table
                    rowKey='Id'
                    className={isLoading ? 'table antd_table no_data_found_hide' : 'table antd_table'}
                    bordered
                    columns={columns}
                    dataSource={Array.isArray(bannerList) && bannerList?.length > 0 ? bannerList : []}
                    pagination={{
                        current: page,
                        pageSize: limit,
                        total: totalBanner,
                        onChange(current, pageSize) {
                            console.log(current);
                            setPage(current);
                            setOffset(current == 1 ? 0 : (limit * current));
                        },
                        hideOnSinglePage: true
                    }}
                />
            </div>
        </div>
    )
}

export default Banner