import React, { useEffect, useState } from 'react'
import { Form, Input, Select, Row, Col, Upload, Button, message } from 'antd'
import CustomEditor from '../../component/CustomEditor/CustomEditor';
import { GetLoggedInUser } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-query';
import { BannerServices, DepartmentServices, JobApplicationsServices } from '../../redux/Services';
import { FaqServices } from '../../redux/Services';
import { TestDepartmentServices } from '../../redux/Services';
import HeadingBack from '../../component/HeadingBack';
import { usePermission } from '../../hooks/usePermission';
import { statecities } from './corporateCity';
import FileUpload from '../BannerManagement/FileUpload';
import { BannerImageAddApi } from '../../redux/Services/BannerServices';
const { Option } = Select;

const AddCorporateTieUp = () => {

    const [form] = Form.useForm();
    let LOG_IN_USER = GetLoggedInUser();
    const [selectedState, setSelectedState] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [file, setFile] = useState("");
    const [showRemarks, setShowRemarks] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { mutate: addCorpTieUp } = useMutation((data) => dispatch(JobApplicationsServices.addCorpTieUpApi(data, navigate)));
    const { mutate: BannerImageAdd } = useMutation((data) => dispatch(BannerServices.BannerImageAddApi(data)));

    const handleSubmit = () => {
        form.validateFields()
            .then((values) => {
                let _data = {
                    ...values,
                    "createdAt": "2024-04-10T14:29:40.000Z",
                    "created_by": null,
                    "updatedAt": "2024-04-10T14:29:40.000Z",
                    "updated_by": null
                };
          
                addCorpTieUp(_data);
            })
            .catch((errorInfo) => {
                console.error("Validation failed:", JSON.stringify(errorInfo));
                message.error("Form validation failed. Please check the fields and try again.");
            });
    };

    const handleSelectChange = value => {
        setShowRemarks(value === "Others");
    };

    const handleStateChange = value => {
        setSelectedState(value);
        setSelectedCity('');
    };

    const handleCityChange = value => {
        setSelectedCity(value);
    };

    const handleUpload = (file) => {
        const validTypes = ['image/png', 'image/jpeg', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

        if (!validTypes.includes(file.type)) {
            message.error("Invalid file type. Please upload PNG, JPG, or Excel files only.");
            return;
        }

        console.log(file);
        const formData = new FormData();
        formData.append("image", file);
        formData.append("dir_location", "banner");

        BannerImageAdd(formData, {
            onSuccess: (res) => {
                var fileName = res.data?.data?.key;
                setFile(fileName);
            },
            onError: (err) => {
                console.error("Error uploading file:", err);
                message.error("File upload failed.");
            }
        });
    };

    useEffect(() => {
        form.setFieldsValue({
            investigations_file: file,

        })
    }, [file])


    const sortedStateCities = [...statecities].sort((a, b) => a.State.localeCompare(b.State));

    return (
        <>
            <HeadingBack title={"Add Corporate Tie UP"} subHeading="" />
            <div className='p-20'>
                < Form
                    form={form}
                    name="basic"
                    layout={"vertical"}
                    autoComplete="off"
                    initialValues={{ Status: "1" }}
                >
                    <Row gutter={[24, 0]}>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="First Name"
                                name="first_name"
                                rules={[
                                    { required: true, message: 'First Name is required' },
                                    { pattern: /^[a-zA-Z\s]*$/, message: 'First Name should not contain special characters or numbers' }
                                ]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder=' Enter First Name' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Last Name"
                                name="last_name"
                                rules={[
                                    { required: true, message: 'Last Name is required' },
                                    { pattern: /^[a-zA-Z\s]*$/, message: 'Last Name should not contain special characters or numbers' }
                                ]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Enter Last Name' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Email"
                                name="email_id"
                                rules={[
                                    { required: true, message: 'Email is required' },
                                    { type: 'email', message: 'Please enter a valid email address' }
                                ]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Enter Email ID' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Mobile"
                                name="contact_number"
                                rules={[
                                    { required: true, message: 'Mobile Number is required' },
                                    { pattern: /^[6-9]\d{9}$/, message: 'Mobile Number should be correct' }
                                ]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder=' Enter Mobile Number' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Corporate Name"
                                name="corporate_name"
                                rules={[
                                    { required: true, message: 'Corporate Name is required' },
                                    { pattern: /^[a-zA-Z\s]*$/, message: 'Corporate Name should not contain numbers or special characters' }
                                ]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Enter Corporate Name' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Company Profile/Nature of Business"
                                name="company_profile"
                                rules={[
                                    { required: true, message: 'Company Profile is required' },
                                    { pattern: /^[a-zA-Z\s]*$/, message: 'Company Profile should not contain numbers or special characters' }
                                ]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder=' Enter Company Profile' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Industry"
                                name="industry"
                                rules={[
                                    { required: true, message: 'Industry is required' },
                                    { pattern: /^[a-zA-Z\s]*$/, message: 'Industry should not contain numbers or special characters' }
                                ]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Enter Industry Name' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Corporate Address"
                                name="corporate_address"
                                rules={[
                                    { required: true, message: 'Corporate Address is required' },
                                    { pattern: /^[a-zA-Z\s]*$/, message: 'Corporate Address should not contain numbers or special characters' }
                                ]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Enter Corporate Address' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Approx Number of Employees PAN INDIA"
                                name="employees_count"
                                rules={[
                                    { required: true, message: 'Approx Number of Employees PAN INDIA is required' },
                                    { pattern: /^\d+$/, message: 'Please enter a valid number' }
                                ]}
                            >
                                <Input type="text" autoComplete="new-off" className='form-control' placeholder='Enter Approx Number of Employees PAN INDIA' />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="State"
                                name="state_name"
                                rules={[{ required: true, message: 'State is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select State'
                                    autoComplete="new-off"
                                    name="DepartmentId"
                                    value={selectedState}
                                    onChange={handleStateChange}
                                >
                                    <Option className='text-capitalize' value={""}>Select State</Option>
                                    {sortedStateCities.map(state => (
                                        <Option className='text-capitalize' value={state.State} key={state.State}>{state.State}</Option>
                                    ))}

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="City"
                                name="city_name"
                                rules={[{ required: true, message: 'City is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select City'
                                    autoComplete="new-off"
                                    name="CategoryId"
                                    value={selectedCity}
                                    onChange={handleCityChange}

                                >
                                    <Option className='text-capitalize' value={""}>Select City</Option>
                                    {sortedStateCities.find(state => state.State === selectedState)?.City.map(city => (
                                        <Option className='text-capitalize' value={city} key={city}>{city}</Option>
                                    ))}

                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Investigations"
                                name="investigations_file"
                                rules={[{ required: false, message: 'Investigations is required' }]}
                            >
                                <FileUpload file={file} setFile={setFile} handleUpload={handleUpload} />
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Corporate Requirement For Tie Up"
                                name="corporate_requirement"
                                rules={[{ required: true, message: 'Corporate Requirement For Tie Up is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Corporate Requirement For Tie Up'
                                    autoComplete="new-off"
                                    name="corporate_requirement"
                                    onChange={handleSelectChange}
                                >
                                    <Option className='text-capitalize' value={""}>Select Corporate Requirement</Option>
                                    <Option className='text-capitalize' value={"Privlage Discount"}>Privlage Discount</Option>
                                    <Option className='text-capitalize' value={"Pre Employment"}>Pre Employment</Option>
                                    <Option className='text-capitalize' value={"Drug Test"}>Drug Test</Option>
                                    <Option className='text-capitalize' value={"Anual Health Checkup"}>Anual Health Checkup</Option>
                                    <Option className='text-capitalize' value={"Hazardeous Test"}>Hazardeous Test</Option>
                                    <Option className='text-capitalize' value={"Others"}>Others</Option>

                                </Select>
                            </Form.Item>
                        </Col>
                        {showRemarks && (
                            <Col lg={12} md={12} sm={24} xs={24}>
                                <Form.Item
                                    label="Remarks"
                                    name="remarks"
                                    rules={[
                                        { required: true, message: 'Remarks is required' },
                                        { pattern: /^[a-zA-Z\s]*$/, message: 'Remarks should not contain numbers or special characters' }
                                    ]}
                                >
                                    <Input type="text" autoComplete="new-off" className='form-control' placeholder='Enter Remarks' />
                                </Form.Item>
                            </Col>
                        )}
                        {/* <Col lg={12} md={12} sm={24} xs={24}>
                            <Form.Item
                                label="Status"
                                name="Status"
                                rules={[{ required: true, message: 'Status is required' }]}
                            >
                                <Select
                                    className='from-control text-capitalize'
                                    placeholder='Select Status'
                                    autoComplete="new-off"
                                    name="Status"
                                >
                                    <Option className='text-capitalize' value={"0"}>Inactive</Option>
                                    <Option className='text-capitalize' value={"1"}>Active</Option>
                                </Select>
                            </Form.Item>
                        </Col> */}
                    </Row>

                    <div className='text-center'>
                        <button key="submit" type="submit" className='gradient-button w-auto' onClick={handleSubmit}>
                            Submit
                        </button>
                    </div>
                </Form >

            </div>
        </>
    )
}

export default AddCorporateTieUp



