import { Editor } from '@tinymce/tinymce-react'
import React from 'react'
const plugins =
    ["link", "image", "code", "table", "textcolor", "colorpicker", "fullscreen", "hr", "lists", "wordcount"];

const toolbar =
    "blocks fontsize | fontselect fontsizeselect formatselect | " +
    "bold italic underline strikethrough subscript superscript | " +
    "blockquote removeformat | forecolor backcolor | " +
    "alignleft aligncenter alignright alignjustify | " +
    "indent outdent | numlist bullist | " +
    "link unlink | hr table image | fullscreen code | undo redo | wordcount";

const CustomEditor = ({ height, onEditorChange, value }) => {
    /* const handleEditorChange = (e) => {
        console.log('Content was updated:', e.target.getContent());
    } */
    return (
        <div>
            <Editor
                // apiKey='hdm7wcpz6cr1fy3ypr3qlbafm5qvgyodzn9dy74xdig9dh4a'
                apiKey='lb4lnzay3a8cqwfxjuk37fs5br3i117io0u9ajiweaicxbrt'
                init={{
                    selector: 'textarea',
                    deprecation_warnings: false,
                    plugins,
                    toolbar,
                    menubar: false,
                    height: height || 300,
                    images_upload_url: ' ',
                    automatic_uploads: false
                }}
                value={value}
                className="form-control w-100"
                onEditorChange={onEditorChange}
            />
        </div>
    )
}

export default CustomEditor